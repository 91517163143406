import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Container,
  VStack,
  HStack,
  Text,
  Input,
  Button,
  List,
  ListItem,
  UnorderedList,
  ListItem as ChakraListItem,
  useToast,
  IconButton
} from '@chakra-ui/react';
import { chat, getRecentChats, getChatHistory } from '../../services/api';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { CloseIcon } from '@chakra-ui/icons';

function Chat() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [recentChats, setRecentChats] = useState([]);
  const [hiddenChatIds, setHiddenChatIds] = useState(() => {
    const saved = localStorage.getItem('hiddenChatIds');
    return saved ? JSON.parse(saved) : [];
  });
  const messagesEndRef = useRef(null);
  const toast = useToast();

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    fetchRecentChats();
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const fetchRecentChats = async () => {
    try {
      const response = await getRecentChats();
      setRecentChats(response.data);
    } catch (error) {
      console.error('Error fetching recent chats:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch recent chats',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const loadChatHistory = async (chatId) => {
    try {
      const response = await getChatHistory(chatId);
      setMessages(response.data.messages);
    } catch (error) {
      console.error('Error loading chat history:', error);
      toast({
        title: 'Error',
        description: 'Failed to load chat history',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSendMessage = async () => {
    if (!input.trim()) return;

    const userMessage = { role: 'user', content: input };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput('');
    setLoading(true);

    try {
        // Get chat ID from either the first message or any message in the conversation
        const chatId = messages.find(msg => msg.chat_id)?.chat_id || null;
        
        const response = await chat(input, messages, chatId);
        
        const assistantMessage = {
            role: 'assistant',
            content: response.data.response,
            recipe_name: response.data.recipe_name,
            recipe_id: response.data.recipe_id,
            ingredients: response.data.ingredients,
            instructions: response.data.instructions,
            chat_id: response.data.chat_id
        };
        
        setMessages((prevMessages) => [...prevMessages, assistantMessage]);
        
        // Only fetch recent chats if this is a new conversation
        if (!chatId) {
            fetchRecentChats();
        }
    } catch (error) {
        console.error('Error sending message:', error);
        toast({
            title: 'Error',
            description: 'Failed to send message. Please try again.',
            status: 'error',
            duration: 3000,
            isClosable: true,
        });
    } finally {
        setLoading(false);
    }
};

  const handleHideChat = (chatId, e) => {
    e.stopPropagation();
    const newHiddenIds = [...hiddenChatIds, chatId];
    setHiddenChatIds(newHiddenIds);
    localStorage.setItem('hiddenChatIds', JSON.stringify(newHiddenIds));
  };

  return (
    <Container maxW="container.xl" py={4} height="calc(100vh - 80px)" display="flex">
      <VStack spacing={4} align="stretch" flex="1">
        <Box flex="1" overflowY="auto" bg="gray.50" p={4} borderRadius="md" minHeight="70vh">
          <List spacing={3}>
            {messages.map((message, index) => (
              <ListItem
                key={index}
                alignSelf={message.role === 'user' ? 'flex-end' : 'flex-start'}
              >
                <Box
                  bg={message.role === 'user' ? 'blue.100' : 'green.100'}
                  p={3}
                  borderRadius="md"
                  maxW="70%"
                >
                  {message.role === 'user' ? (
                    <Text>{message.content}</Text>
                  ) : (
                    <VStack align="start" spacing={2}>
                      <Text>{message.content}</Text>

                      {message.recipe_name && message.recipe_id && (
                        <Text>
                          <Text as="span" fontWeight="bold">
                            Recipe Name:
                          </Text>{' '}
                          <Link to={`/recipes/${message.recipe_id}`} style={{ color: 'blue' }}>
                            {message.recipe_name}
                          </Link>
                        </Text>
                      )}

                      {message.ingredients && message.ingredients.length > 0 && (
                        <>
                          <Text fontWeight="bold">Ingredients:</Text>
                          <UnorderedList pl={5}>
                            {message.ingredients.map((ing, idx) => (
                              <ChakraListItem key={idx}>
                                {ing.quantity} {ing.unit} {ing.name}
                              </ChakraListItem>
                            ))}
                          </UnorderedList>
                        </>
                      )}

                      {message.instructions && (
                        <>
                          <Text fontWeight="bold">Instructions:</Text>
                          <Text>{message.instructions}</Text>
                        </>
                      )}
                    </VStack>
                  )}
                </Box>
              </ListItem>
            ))}
            <div ref={messagesEndRef} />
          </List>
        </Box>
        <Box position="sticky" bottom="0" bg="white">
          <HStack as="form" onSubmit={(e) => { e.preventDefault(); handleSendMessage(); }} mb={2}>
            <Input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Type your message..."
            />
            <Button colorScheme="teal" onClick={handleSendMessage} isLoading={loading}>
              Send
            </Button>
          </HStack>
          <Box borderTop="1px" borderColor="gray.200" pt={2} mt={4}>
            <Text fontSize="sm" fontWeight="bold" mb={2}>Recent Conversations</Text>
            <HStack spacing={4} overflowX="auto" pb={2}>
              {recentChats
                .filter(chat => !hiddenChatIds.includes(chat.id))
                .slice(0, 5)
                .map((chat) => (
                  <Button
                    key={chat.id}
                    size="sm"
                    variant="outline"
                    onClick={() => loadChatHistory(chat.id)}
                    position="relative"
                    pr="6"
                  >
                    {chat.title || 'New Chat'}
                    <IconButton
                      icon={<CloseIcon />}
                      size="xs"
                      position="absolute"
                      right="1"
                      top="1"
                      onClick={(e) => handleHideChat(chat.id, e)}
                      aria-label="Hide chat"
                      variant="ghost"
                      minW="4"
                      height="4"
                      fontSize="8px"
                    />
                  </Button>
                ))}
            </HStack>
          </Box>
        </Box>
      </VStack>
    </Container>
  );
}

export default Chat;