import React, { useState, useRef, useCallback } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  HStack,
  Box,
  Text,
  IconButton,
  Image,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Select,
  Progress,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon, AddIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { processReceipt } from '../../services/api';
import Webcam from 'react-webcam';

function ReceiptUploadModal({ isOpen, onClose, onSubmit }) {
  const [images, setImages] = useState([]);
  const [extractedItems, setExtractedItems] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const fileInputRef = useRef();
  const toast = useToast();
  const [showCamera, setShowCamera] = useState(false);
  const webcamRef = useRef(null);

  const handleFileUpload = async (event) => {
    const files = Array.from(event.target.files);
    const newImages = [];
    
    for (const file of files) {
      if (!file.type.startsWith('image/')) {
        toast({
          title: 'Invalid file type',
          description: 'Please upload only image files',
          status: 'error',
          duration: 3000,
        });
        continue;
      }
      newImages.push({
        file,
        preview: URL.createObjectURL(file)
      });
    }
    
    setImages([...images, ...newImages]);
  };

  const handleCameraCapture = useCallback(() => {
    setShowCamera(true);
  }, []);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      fetch(imageSrc)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], "camera-capture.jpg", { type: 'image/jpeg' });
          setImages(prev => [...prev, {
            file,
            preview: URL.createObjectURL(file)
          }]);
          setShowCamera(false);
        });
    }
  }, [webcamRef]);

  const processImages = async () => {
    setProcessing(true);
    try {
      for (const image of images) {
        const formData = new FormData();
        formData.append('image', image.file);
        
        const response = await processReceipt(formData);
        setExtractedItems(prev => [...prev, ...response.items]);
      }
    } catch (error) {
      toast({
        title: 'Error processing receipt',
        description: error.message,
        status: 'error',
        duration: 3000,
      });
    } finally {
      setProcessing(false);
    }
  };

  const handleDeleteImage = (index) => {
    const newImages = [...images];
    URL.revokeObjectURL(newImages[index].preview);
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const handleEditItem = (item) => {
    setEditingItem({ ...item });
  };

  const handleSaveEdit = () => {
    setExtractedItems(prev =>
      prev.map(item =>
        item.id === editingItem.id ? editingItem : item
      )
    );
    setEditingItem(null);
  };

  const handleDeleteItem = (id) => {
    setExtractedItems(prev => prev.filter(item => item.id !== id));
  };

  const handleSubmit = () => {
    onSubmit(extractedItems);
    setImages([]);
    setExtractedItems([]);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Receipts</ModalHeader>
        <ModalBody>
          <VStack spacing={4}>
            <HStack>
              <Button
                leftIcon={<AddIcon />}
                onClick={() => fileInputRef.current.click()}
              >
                Upload Images
              </Button>
              <Button
                leftIcon={<AddIcon />}
                onClick={handleCameraCapture}
              >
                Take Photo
              </Button>
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={handleFileUpload}
                ref={fileInputRef}
                style={{ display: 'none' }}
              />
            </HStack>

            {images.length > 0 && (
              <Box w="100%">
                <Text mb={2}>Uploaded Images:</Text>
                <HStack spacing={4} overflowX="auto" p={2}>
                  {images.map((image, index) => (
                    <Box key={index} position="relative">
                      <Image
                        src={image.preview}
                        alt={`Receipt ${index + 1}`}
                        maxH="100px"
                        borderRadius="md"
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        size="xs"
                        position="absolute"
                        top={1}
                        right={1}
                        onClick={() => handleDeleteImage(index)}
                      />
                    </Box>
                  ))}
                </HStack>
                {!processing && images.length > 0 && (
                  <Button
                    mt={4}
                    colorScheme="teal"
                    onClick={processImages}
                  >
                    Process Images
                  </Button>
                )}
                {processing && <Progress size="xs" isIndeterminate mt={4} />}
              </Box>
            )}

            {extractedItems.length > 0 && (
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Quantity</Th>
                    <Th>Unit</Th>
                    <Th>Location</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {extractedItems.map((item) => (
                    <Tr key={item.id}>
                      {editingItem?.id === item.id ? (
                        <>
                          <Td>
                            <Input
                              value={editingItem.name}
                              onChange={(e) => setEditingItem({
                                ...editingItem,
                                name: e.target.value
                              })}
                            />
                          </Td>
                          <Td>
                            <Input
                              type="number"
                              value={editingItem.quantity}
                              onChange={(e) => setEditingItem({
                                ...editingItem,
                                quantity: e.target.value
                              })}
                            />
                          </Td>
                          <Td>
                            <Input
                              value={editingItem.unit}
                              onChange={(e) => setEditingItem({
                                ...editingItem,
                                unit: e.target.value
                              })}
                            />
                          </Td>
                          <Td>
                            <Select
                              value={editingItem.location}
                              onChange={(e) => setEditingItem({
                                ...editingItem,
                                location: e.target.value
                              })}
                            >
                              <option value="pantry">Pantry</option>
                              <option value="fridge">Fridge</option>
                              <option value="freezer">Freezer</option>
                            </Select>
                          </Td>
                          <Td>
                            <HStack>
                              <IconButton
                                icon={<CheckIcon />}
                                onClick={handleSaveEdit}
                                size="sm"
                              />
                              <IconButton
                                icon={<CloseIcon />}
                                onClick={() => setEditingItem(null)}
                                size="sm"
                              />
                            </HStack>
                          </Td>
                        </>
                      ) : (
                        <>
                          <Td>{item.name}</Td>
                          <Td>{item.quantity}</Td>
                          <Td>{item.unit}</Td>
                          <Td>{item.location}</Td>
                          <Td>
                            <HStack>
                              <IconButton
                                icon={<EditIcon />}
                                onClick={() => handleEditItem(item)}
                                size="sm"
                              />
                              <IconButton
                                icon={<DeleteIcon />}
                                onClick={() => handleDeleteItem(item.id)}
                                size="sm"
                              />
                            </HStack>
                          </Td>
                        </>
                      )}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}

            {showCamera && (
              <Box position="relative">
                <Webcam
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width="100%"
                />
                <Button
                  position="absolute"
                  bottom="4"
                  left="50%"
                  transform="translateX(-50%)"
                  onClick={capture}
                >
                  Take Photo
                </Button>
              </Box>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="teal" mr={3} onClick={handleSubmit}>
            Add to Inventory
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ReceiptUploadModal; 