import api from './api';

export const calculateNutrition = async (ingredients, instructions) => {
  try {
    const response = await api.post('/calculate-nutrition/', {
      ingredients,
      instructions
    });
    return response.data;
  } catch (error) {
    console.error('Error calculating nutrition:', error);
    throw error;
  }
};
