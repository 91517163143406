import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';
import { ChakraProvider, Spinner, Center } from '@chakra-ui/react';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Dashboard from './components/Dashboard/Dashboard';
import Inventory from './components/Inventory/Inventory';
import Recipes from './components/Recipes/Recipes';
import RecipeDetail from './components/Recipes/RecipeDetail'; // Import RecipeDetail
import MealPlanner from './components/MealPlanner/MealPlanner';
import GroceryList from './components/GroceryList/GroceryList';
import Chat from './components/Chat/Chat';
import Profile from './components/Profile/Profile';
import Layout from './components/Layout/Layout';
import LandingPage from './components/LandingPage/LandingPage';
import InitialSetup from './components/InitialSetup/InitialSetup'; // Add this import
import theme from './theme';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <ChakraProvider theme={theme}>
        <Center height="100vh">
          <Spinner size="xl" />
        </Center>
      </ChakraProvider>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route 
            path="/" 
            element={
              isAuthenticated ? (
                <Navigate to="/chat" />
              ) : (
                <LandingPage />
              )
            } 
          />
          <Route 
            path="/login" 
            element={
              isAuthenticated ? (
                <Navigate to="/chat" />
              ) : (
                <Login setIsAuthenticated={setIsAuthenticated} />
              )
            } 
          />
          <Route 
            path="/register" 
            element={
              isAuthenticated ? (
                <Navigate to="/initial-setup" />
              ) : (
                <Register setIsAuthenticated={setIsAuthenticated} />
              )
            } 
          />
          <Route 
            path="/initial-setup" 
            element={
              isAuthenticated ? (
                <InitialSetup />
              ) : (
                <Navigate to="/login" />
              )
            } 
          />

          <Route
            path="/*"
            element={
              isAuthenticated ? (
                <Layout>
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/inventory" element={<Inventory />} />
                    <Route path="/recipes" element={<Recipes />} />
                    <Route path="/recipes/:id" element={<RecipeDetail />} />
                    <Route path="/meal-planner" element={<MealPlanner />} />
                    <Route path="/grocery-list" element={<GroceryList />} />
                    <Route path="/chat" element={<Chat />} />
                    <Route path="/profile" element={<Profile />} />
                  </Routes>
                </Layout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;