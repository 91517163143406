import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://api.usepantry.com';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 60000,
  keepalive: true,
  maxRedirects: 5,
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  console.log('Token:', token); // Add this line
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      window.location = '/login';
    }
    return Promise.reject(error);
  }
);

export const login = (username, password) => api.post('/login/', { username, password });
export const register = (username, email, password) => api.post('/register/', { username, email, password });
export const initialSetup = (ingredients) => api.post('/initial-setup/', { ingredients });
export const getIngredients = () => api.get('/ingredients/');
export const updateIngredient = (id, data) => api.patch(`/ingredients/${id}/`, data);
export const createIngredient = (data) => api.post('/ingredients/', data);
export const deleteIngredient = (id) => api.delete(`/ingredients/${id}/`);
export const getRecipes = () => api.get('/recipes/');
export const getMealPlans = () => api.get('/meal-plans/');
export const getGroceryList = () => api.get('/grocery-lists/');
export const toggleGroceryItem = (itemId) => api.post('/grocery-lists/toggle_item/', { item_id: itemId });
export const chat = (message, previousMessages = [], chatId = null) => {
  const controller = new AbortController();
  
  return api.post('/chat/', 
    { 
      message, 
      previous_messages: previousMessages, 
      chat_id: chatId 
    },
    {
      signal: controller.signal,
      timeout: 60000,
      headers: {
        'Keep-Alive': 'timeout=60'
      }
    }
  );
};
export const addToGroceryList = (items) => api.post('/grocery-lists/add_items/', { items });
export const getRecipe = (id) => api.get(`/recipes/${id}/`);

export const deleteRecipe = (id) => api.delete(`/recipes/${id}/`);
export const deleteMealPlan = (id) => api.delete(`/meal-plans/${id}/`);
export const deleteGroceryItem = (itemId) => api.delete('/grocery-lists/delete_item/', { data: { item_id: itemId } });

export const updateGroceryList = async (recipeId) => {
  try {
    const response = await api.post('/update-grocery-list/', { recipe_id: recipeId });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addToMealPlan = (data) => api.post('/meal-plans/', data);

export const checkOffGroceryItem = (itemId) => api.post('/grocery-lists/check-off-item/', { item_id: itemId });

export const extractRecipeFromURL = (url) => api.post('/extract-recipe/', { url });

export const getUserProfile = () => api.get('/user-profiles/me/');
export const updateUserProfile = (data) => api.put('/user-profiles/update_me/', data);

export const calculateNutrition = async (ingredients, instructions) => {
  try {
    const response = await api.post('/calculate-nutrition/', {
      ingredients,
      instructions
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRecentChats = () => api.get('/chats/recent/');
export const getChatHistory = (chatId) => api.get(`/chats/${chatId}/`);

export const updateMealPlan = async (id, updatedMealPlan) => {
  const response = await api.put(`/meal-plans/${id}/`, updatedMealPlan);
  return response.data;
};

export const processReceipt = async (formData) => {
  try {
    const response = await api.post('/process-receipt/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 30000,
    });
    
    if (!response.data || !response.data.items) {
      throw new Error('Invalid response format from server');
    }
    
    return response.data;
  } catch (error) {
    if (error.code === 'ECONNABORTED') {
      throw new Error('Request timed out. Please try again with a smaller image.');
    }
    if (error.response) {
      throw new Error(error.response.data.error || 'Failed to process receipt');
    }
    throw new Error('Failed to process receipt: ' + (error.message || 'Unknown error'));
  }
};

export default api;