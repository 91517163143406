import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Heading,
  VStack,
  HStack,
  Text,
  Button,
  IconButton,
  Checkbox,
  List,
  ListItem,
  useToast
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { getGroceryList, deleteGroceryItem, checkOffGroceryItem } from '../../services/api';

function GroceryList() {
  const [groceryItems, setGroceryItems] = useState([]);
  const toast = useToast();

  useEffect(() => {
    fetchGroceryList();
  }, []);

  const fetchGroceryList = async () => {
    try {
      const response = await getGroceryList();
      console.log('Grocery List Response:', response.data);
      const items = response.data?.items || [];
      setGroceryItems(items);
    } catch (error) {
      console.error('Error fetching grocery list:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch grocery list. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      await deleteGroceryItem(id);
      fetchGroceryList();
      toast({
        title: 'Success',
        description: 'Item deleted from grocery list',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting grocery item:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete item. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCheckOffItem = async (id, isChecked) => {
    try {
      await checkOffGroceryItem(id, isChecked);
      fetchGroceryList();
      toast({
        title: 'Success',
        description: `Item ${isChecked ? 'checked' : 'unchecked'}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error checking off grocery item:', error);
      toast({
        title: 'Error',
        description: 'Failed to update item. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Container maxW="container.xl" py={8}>
      <Heading mb={6}>Grocery List</Heading>
      <VStack spacing={4} align="stretch">
        <List spacing={3}>
          {groceryItems && Array.isArray(groceryItems) && groceryItems.map((item) => (
            <ListItem key={item.id} p={2} bg="gray.50" borderRadius="md">
              <HStack justify="space-between">
                <HStack>
                  <Checkbox
                    isChecked={item.checked}
                    onChange={(e) => handleCheckOffItem(item.id, e.target.checked)}
                  />
                  <Text textDecoration={item.checked ? 'line-through' : 'none'}>
                    {item.name} - {item.quantity} {item.unit}
                  </Text>
                </HStack>
                <IconButton
                  size="sm"
                  icon={<DeleteIcon />}
                  onClick={() => handleDeleteItem(item.id)}
                  aria-label="Delete item"
                />
              </HStack>
            </ListItem>
          ))}
        </List>
        <Button colorScheme="teal" onClick={fetchGroceryList}>
          Refresh Grocery List
        </Button>
      </VStack>
    </Container>
  );
}

export default GroceryList;