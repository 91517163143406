import React, { useState } from 'react';
import {
  Container,
  Box,
  VStack,
  HStack,
  Heading,
  Text,
  Button,
  IconButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  useToast,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { AudioRecorder } from 'react-audio-voice-recorder';
import { transcribeAudio } from '../../services/openai';
import { initialSetup } from '../../services/api';

function InitialSetup() {
  const [ingredients, setIngredients] = useState({
    pantry: [],
    fridge: [],
    freezer: []
  });
  const [recordings, setRecordings] = useState({
    pantry: [],
    fridge: [],
    freezer: []
  });
  const [editingItem, setEditingItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const handleNewRecording = async (blob, category) => {
    if (recordings[category].length >= 10) {
      toast({
        title: 'Maximum recordings reached',
        description: 'You can only have 10 recordings per category',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);
    try {
      const newRecording = {
        id: Date.now(),
        blob,
        url: URL.createObjectURL(blob)
      };
      setRecordings(prev => ({
        ...prev,
        [category]: [...prev[category], newRecording]
      }));

      const parsedIngredients = await transcribeAudio(blob);
      setIngredients(prev => ({
        ...prev,
        [category]: [...prev[category], ...parsedIngredients.map(ing => ({
          ...ing,
          location: category,
          id: Date.now() + Math.random()
        }))]
      }));
    } catch (error) {
      toast({
        title: 'Error processing recording',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRecording = (category, recordingId) => {
    setRecordings(prev => ({
      ...prev,
      [category]: prev[category].filter(rec => rec.id !== recordingId)
    }));
  };

  const handleEditItem = (item) => {
    setEditingItem({ ...item });
  };

  const handleSaveEdit = () => {
    setIngredients(prev => ({
      ...prev,
      [editingItem.location]: prev[editingItem.location].map(ing => 
        ing.id === editingItem.id ? editingItem : ing
      )
    }));
    setEditingItem(null);
  };

  const handleDeleteItem = (category, itemId) => {
    setIngredients(prev => ({
      ...prev,
      [category]: prev[category].filter(ing => ing.id !== itemId)
    }));
  };

  const handleAddManualItem = (category) => {
    const newItem = {
      id: Date.now(),
      name: '',
      quantity: '',
      unit: '',
      location: category
    };
    setIngredients(prev => ({
      ...prev,
      [category]: [...prev[category], newItem]
    }));
    setEditingItem(newItem);
  };

  const handleSubmit = async () => {
    try {
      const allIngredients = [
        ...ingredients.pantry,
        ...ingredients.fridge,
        ...ingredients.freezer
      ];
      console.log('Submitting ingredients:', allIngredients);
      await initialSetup(allIngredients);
      navigate('/dashboard');
      toast({
        title: 'Setup completed',
        description: 'Your pantry has been initialized successfully!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Initial setup error:', error.response?.data || error);
      toast({
        title: 'Error',
        description: error.response?.data?.error || 'Failed to complete setup. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={6} align="stretch">
        <Box textAlign="center" mb={6}>
          <Heading mb={3}>Welcome to Pantry!</Heading>
          <Text fontSize="lg">
            Let's set up your initial inventory. You can record ingredients by speaking
            or add them manually. For voice recordings, try to include quantities when possible
            (e.g., "2 pounds of rice, 1 gallon of milk").
          </Text>
        </Box>

        <Tabs isFitted variant="enclosed">
          <TabList mb="1em">
            <Tab>Pantry</Tab>
            <Tab>Fridge</Tab>
            <Tab>Freezer</Tab>
          </TabList>

          <TabPanels>
            {['pantry', 'fridge', 'freezer'].map((category) => (
              <TabPanel key={category}>
                <VStack spacing={4} align="stretch">
                  <Box p={4} borderWidth="1px" borderRadius="lg" bg="white">
                    <Heading size="md" mb={4}>Voice Recordings ({recordings[category].length}/10)</Heading>
                    <HStack mb={4}>
                      <AudioRecorder 
                        onRecordingComplete={(blob) => handleNewRecording(blob, category)}
                        disabled={recordings[category].length >= 10 || loading}
                      />
                      {loading && <Text color="gray.500">Processing recording...</Text>}
                    </HStack>
                    
                    {recordings[category].map((recording) => (
                      <HStack key={recording.id} spacing={4} mt={2}>
                        <audio src={recording.url} controls />
                        <IconButton
                          icon={<DeleteIcon />}
                          onClick={() => handleDeleteRecording(category, recording.id)}
                          aria-label="Delete recording"
                          size="sm"
                        />
                      </HStack>
                    ))}
                  </Box>

                  <Box p={4} borderWidth="1px" borderRadius="lg" bg="white">
                    <HStack justify="space-between" mb={4}>
                      <Heading size="md">Ingredients List</Heading>
                      <Button
                        size="sm"
                        colorScheme="teal"
                        onClick={() => handleAddManualItem(category)}
                      >
                        Add Item Manually
                      </Button>
                    </HStack>

                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Name</Th>
                          <Th>Quantity</Th>
                          <Th>Unit</Th>
                          <Th>Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {ingredients[category].map((item) => (
                          <Tr key={item.id}>
                            {editingItem?.id === item.id ? (
                              <>
                                <Td>
                                  <Input
                                    value={editingItem.name}
                                    onChange={(e) => setEditingItem({
                                      ...editingItem,
                                      name: e.target.value
                                    })}
                                  />
                                </Td>
                                <Td>
                                  <Input
                                    type="number"
                                    value={editingItem.quantity}
                                    onChange={(e) => setEditingItem({
                                      ...editingItem,
                                      quantity: e.target.value
                                    })}
                                  />
                                </Td>
                                <Td>
                                  <Input
                                    value={editingItem.unit}
                                    onChange={(e) => setEditingItem({
                                      ...editingItem,
                                      unit: e.target.value
                                    })}
                                  />
                                </Td>
                                <Td>
                                  <HStack spacing={2}>
                                    <IconButton
                                      icon={<CheckIcon />}
                                      onClick={handleSaveEdit}
                                      aria-label="Save edit"
                                      colorScheme="green"
                                      size="sm"
                                    />
                                    <IconButton
                                      icon={<CloseIcon />}
                                      onClick={() => setEditingItem(null)}
                                      aria-label="Cancel edit"
                                      colorScheme="red"
                                      size="sm"
                                    />
                                  </HStack>
                                </Td>
                              </>
                            ) : (
                              <>
                                <Td>{item.name}</Td>
                                <Td>{item.quantity}</Td>
                                <Td>{item.unit}</Td>
                                <Td>
                                  <HStack spacing={2}>
                                    <IconButton
                                      icon={<EditIcon />}
                                      onClick={() => handleEditItem(item)}
                                      aria-label="Edit item"
                                      size="sm"
                                    />
                                    <IconButton
                                      icon={<DeleteIcon />}
                                      onClick={() => handleDeleteItem(category, item.id)}
                                      aria-label="Delete item"
                                      size="sm"
                                    />
                                  </HStack>
                                </Td>
                              </>
                            )}
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                </VStack>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>

        <Button
          colorScheme="teal"
          size="lg"
          onClick={handleSubmit}
          isLoading={loading}
        >
          Complete Setup
        </Button>
      </VStack>
    </Container>
  );
}

export default InitialSetup;