import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Heading,
  VStack,
  HStack,
  Text,
  Button,
  IconButton,
  Grid,
  GridItem,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Select
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, DeleteIcon, AddIcon } from '@chakra-ui/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getMealPlans, deleteMealPlan, updateGroceryList, addToMealPlan, updateMealPlan, getRecipes } from '../../services/api';

function MealPlanner() {
  const [mealPlans, setMealPlans] = useState([]);
  const [currentWeekStart, setCurrentWeekStart] = useState(getStartOfWeek(new Date()));
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedDate, setSelectedDate] = useState(null);
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState(null);

  useEffect(() => {
    fetchMealPlans();
  }, []);

  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        const response = await getRecipes();
        setRecipes(response.data);
      } catch (error) {
        console.error('Error fetching recipes:', error);
      }
    };
    fetchRecipes();
  }, []);

  const fetchMealPlans = async () => {
    try {
      const response = await getMealPlans();
      setMealPlans(response.data);
    } catch (error) {
      console.error('Error fetching meal plans:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch meal plans',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  function getStartOfWeek(date) {
    const newDate = new Date(date);
    const day = newDate.getDay();
    const diff = newDate.getDate() - day;
    return new Date(newDate.setDate(diff));
  }

  const getWeekDates = () => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(currentWeekStart);
      date.setDate(date.getDate() + i);
      dates.push(date);
    }
    return dates;
  };

  const weekDates = getWeekDates();

  const navigateWeek = (direction) => {
    const newDate = new Date(currentWeekStart);
    newDate.setDate(newDate.getDate() + (direction === 'next' ? 7 : -7));
    setCurrentWeekStart(newDate);
  };

  const getMealsForDate = (date) => {
    const dateStr = date.toISOString().split('T')[0];
    return mealPlans.filter(meal => meal.date.split('T')[0] === dateStr);
  };

  const handleDeleteMealPlan = async (id) => {
    try {
      const response = await deleteMealPlan(id);
      if (response.status === 204 || (response.data && response.data.status === 'success')) {
        fetchMealPlans();
        toast({
          title: 'Success',
          description: 'Meal plan deleted successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error deleting meal plan:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete meal plan',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddToGroceryList = async (recipeId) => {
    try {
      await updateGroceryList(recipeId);
      toast({
        title: 'Success',
        description: 'Added to grocery list',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update grocery list',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddMeal = async (recipeId) => {
    try {
      if (!selectedDate || !recipeId) return;
      
      const response = await addToMealPlan({
        recipe: recipeId,
        date: selectedDate.toISOString().split('T')[0]
      });
      
      if (response.status === 201 || response.data) {
        await fetchMealPlans();  // Wait for the fetch to complete
        setSelectedRecipe(null);
        onClose();
        toast({
          title: 'Success',
          description: 'Meal added to plan',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error adding meal to plan:', error);
      toast({
        title: 'Error',
        description: error.response?.data?.error || 'Failed to add meal to plan',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const { source, destination, draggableId } = result;
    
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const mealPlan = mealPlans.find(meal => meal.id.toString() === draggableId);
    if (!mealPlan) {
      toast({
        title: 'Error',
        description: 'Could not find the meal plan to update',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const newDate = new Date(destination.droppableId);
      await updateMealPlan(mealPlan.id, {
        recipe: mealPlan.recipe.id,
        date: newDate.toISOString().split('T')[0]
      });

      fetchMealPlans();
      
      toast({
        title: 'Success',
        description: 'Meal plan updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating meal plan:', error);
      toast({
        title: 'Error',
        description: 'Failed to update meal plan',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleModalClose = () => {
    setSelectedRecipe(null);
    onClose();
  };

  const handleAddToMealPlan = async (id) => {
    try {
      await addToMealPlan(id);
      toast({
        title: 'Added to meal plan',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding to meal plan:', error);
      toast({
        title: 'Error adding to meal plan',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={6} align="stretch">
        <HStack justify="space-between">
          <Heading>Meal Planner</Heading>
          <HStack>
            <IconButton
              icon={<ChevronLeftIcon />}
              onClick={() => navigateWeek('prev')}
              aria-label="Previous week"
            />
            <Text fontWeight="bold">
              {currentWeekStart.toLocaleDateString()} - {
                new Date(currentWeekStart.getTime() + 6 * 24 * 60 * 60 * 1000).toLocaleDateString()
              }
            </Text>
            <IconButton
              icon={<ChevronRightIcon />}
              onClick={() => navigateWeek('next')}
              aria-label="Next week"
            />
          </HStack>
        </HStack>

        <DragDropContext onDragEnd={onDragEnd}>
          <Grid templateColumns="repeat(7, 1fr)" gap={4}>
            {weekDates.map((date) => (
              <GridItem
                key={date.toISOString()}
                borderWidth={1}
                borderRadius="lg"
                p={4}
                onClick={() => {
                  setSelectedDate(date);
                  onOpen();
                }}
                _hover={{ bg: "gray.50" }}
              >
                <Text fontWeight="bold" mb={2}>
                  {date.toLocaleDateString('en-US', { weekday: 'short' })}
                  <br />
                  {date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                </Text>

                <Droppable droppableId={date.toISOString()}>
                  {(provided, snapshot) => (
                    <VStack
                      align="stretch"
                      spacing={2}
                      minHeight="100px"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      bg={snapshot.isDraggingOver ? "gray.100" : "transparent"}
                      borderRadius="md"
                      transition="background-color 0.2s"
                    >
                      {getMealsForDate(date).map((meal, index) => (
                        <Draggable
                          key={meal.id}
                          draggableId={meal.id.toString()}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              p={2}
                              bg={snapshot.isDragging ? "teal.50" : "gray.50"}
                              borderRadius="md"
                              boxShadow={snapshot.isDragging ? "md" : "none"}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <Text fontSize="sm" fontWeight="medium">
                                {meal.recipe.name}
                              </Text>
                              <HStack spacing={1} mt={1}>
                                <IconButton
                                  size="xs"
                                  icon={<DeleteIcon />}
                                  onClick={() => handleDeleteMealPlan(meal.id)}
                                  aria-label="Delete meal"
                                />
                                <IconButton
                                  size="xs"
                                  icon={<AddIcon />}
                                  onClick={() => handleAddToGroceryList(meal.recipe.id)}
                                  aria-label="Add to grocery list"
                                />
                              </HStack>
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </VStack>
                  )}
                </Droppable>
              </GridItem>
            ))}
          </Grid>
        </DragDropContext>

        <Modal isOpen={isOpen} onClose={handleModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Meal to Plan</ModalHeader>
            <ModalBody>
              <VStack spacing={4}>
                <Text>Select a recipe to add to {selectedDate?.toLocaleDateString()}</Text>
                <Select
                  placeholder="Select a recipe"
                  value={selectedRecipe}
                  onChange={(e) => setSelectedRecipe(e.target.value)}
                >
                  {recipes.map((recipe) => (
                    <option key={recipe.id} value={recipe.id}>
                      {recipe.name}
                    </option>
                  ))}
                </Select>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="teal"
                mr={3}
                onClick={() => handleAddMeal(selectedRecipe)}
                isDisabled={!selectedRecipe}
              >
                Add
              </Button>
              <Button variant="ghost" onClick={handleModalClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </VStack>
    </Container>
  );
}

export default MealPlanner;