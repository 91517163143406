import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Heading, Text, VStack, UnorderedList, ListItem, Spinner, SimpleGrid, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import { getRecipe } from '../../services/api';
import { calculateNutrition } from '../../services/nutrition';

function RecipeDetail() {
  const [recipe, setRecipe] = useState(null);
  const [nutrition, setNutrition] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const isHealthMode = localStorage.getItem('healthMode') === 'true';

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getRecipe(id);
        setRecipe(response.data);
        
        if (isHealthMode) {
          const nutritionData = await calculateNutrition(
            response.data.ingredients,
            response.data.instructions
          );
          setNutrition(nutritionData);
        }
      } catch (error) {
        setError('Failed to fetch recipe data');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id, isHealthMode]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  if (!recipe) {
    return <Text>No recipe found.</Text>;
  }

  const NutritionInfo = () => {
    if (!isHealthMode || !nutrition) return null;
    
    return (
      <Box mt={4} p={4} borderWidth="1px" borderRadius="lg">
        <Heading as="h3" size="md" mb={2}>Nutrition Information (per serving)</Heading>
        <SimpleGrid columns={2} spacing={4}>
          <Stat>
            <StatLabel>Calories</StatLabel>
            <StatNumber>{nutrition.calories}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Protein</StatLabel>
            <StatNumber>{nutrition.protein}g</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Carbs</StatLabel>
            <StatNumber>{nutrition.carbs}g</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Fat</StatLabel>
            <StatNumber>{nutrition.fat}g</StatNumber>
          </Stat>
        </SimpleGrid>
        <Text mt={2} fontSize="sm">Servings per recipe: {nutrition.servings}</Text>
      </Box>
    );
  };

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={6} align="start">
        <Heading as="h1" size="xl">{recipe.name}</Heading>
        <Box width="100%">
          <Heading as="h2" size="lg" mb={2}>Ingredients:</Heading>
          <UnorderedList>
            {recipe.ingredients.map((ingredient, index) => (
              <ListItem key={index}>
                {`${ingredient.quantity}${ingredient.unit ? ' ' + ingredient.unit : ''} ${ingredient.ingredient_name}`.trim()}
              </ListItem>
            ))}
          </UnorderedList>
        </Box>
        <Box width="100%">
          <Heading as="h2" size="lg" mb={2}>Instructions:</Heading>
          <Text>{recipe.instructions}</Text>
        </Box>
        {isHealthMode && <NutritionInfo />}
      </VStack>
    </Container>
  );
}

export default RecipeDetail;