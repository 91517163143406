import axios from 'axios';

export const transcribeAudio = async (audioBlob) => {
  if (audioBlob.size > 25 * 1024 * 1024) {
    throw new Error('Audio file size must be less than 25MB');
  }

  const formData = new FormData();
  formData.append('file', audioBlob, 'audio.wav');
  formData.append('model', 'whisper-1');
  formData.append('language', 'en');
  formData.append('response_format', 'json');
  formData.append('prompt', 'This is a casual conversation about kitchen ingredients. The speaker might mention multiple ingredients with their quantities and units.');

  try {
    const response = await axios.post('https://api.openai.com/v1/audio/transcriptions', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
      },
    });

    const parseResponse = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4',
        messages: [
          {
            role: 'system',
            content: `You are a helpful kitchen assistant that extracts individual ingredients from casual conversation.
                     Return a JSON object with an "ingredients" array.
                     Each ingredient should be its own object with:
                     - name (required, string, capitalize first letter of each word)
                     - quantity (number, default to 1 if missing)
                     - unit (string, use common units for ingredients:
                        - eggs -> dozen
                        - milk, juice -> gallon
                        - fruits, vegetables -> piece
                        - spices, herbs -> ounce
                        - meat -> pound
                        - rice, flour, sugar -> pound
                        - liquids -> fluid ounce
                     )`
          },
          {
            role: 'user',
            content: `Extract individual ingredients from this conversation: ${response.data.text}`
          }
        ]
      },
      {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );

    const result = JSON.parse(parseResponse.data.choices[0].message.content);
    console.log('Parsed ingredients:', result);
    return result.ingredients || [];
  } catch (error) {
    console.error('OpenAI API error:', error.response?.data || error);
    throw new Error('Failed to process audio. Please try again or add items manually.');
  }
}; 