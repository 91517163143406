import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Drawer,
  DrawerContent,
  useDisclosure,
  IconButton,
  Text,
  VStack,
  HStack,
  Button,
  Icon,
} from '@chakra-ui/react';
import {
  FiMenu,
  FiHome,
  FiUser,
  FiMessageSquare,
  FiList,
  FiBookOpen,
  FiCalendar,
  FiShoppingCart,
  FiHeart,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';

const LinkItems = [
  { name: 'Chat', icon: FiMessageSquare, path: '/chat' },
  { name: 'Inventory', icon: FiHome, path: '/inventory' },
  { name: 'Recipes', icon: FiBookOpen, path: '/recipes' },
  { name: 'Meal Planner', icon: FiCalendar, path: '/meal-planner' },
  { name: 'Grocery List', icon: FiShoppingCart, path: '/grocery-list' },
  { name: 'Profile', icon: FiUser, path: '/profile' },
];

function Layout({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh">
      <SidebarContent onClose={onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer isOpen={isOpen} onClose={onClose} placement="left">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => (
  <Box
    bg="gray.100"
    borderRight="1px"
    borderRightColor="gray.200"
    w={{ base: 'full', md: 60 }}
    pos="fixed"
    h="full"
    {...rest}
  >
    <Flex h="20" alignItems="center" mx="0" justifyContent="flex-start">
      <img src="/logo.png" alt="Logo" style={{ width: '200px', height: 'auto' }} />
      <IconButton display={{ base: 'flex', md: 'none' }} onClick={onClose} icon={<FiMenu />} />
    </Flex>
    {LinkItems.map((link) => (
      <NavItem key={link.name} icon={link.icon} path={link.path}>
        {link.name}
      </NavItem>
    ))}
    <HealthModeToggle />
  </Box>
);

const NavItem = ({ icon, children, path, ...rest }) => (
  <Link to={path} style={{ textDecoration: 'none' }}>
    <Flex
      align="center"
      p="4"
      mx="4"
      borderRadius="lg"
      role="group"
      cursor="pointer"
      _hover={{
        bg: 'teal.400',
        color: 'white',
      }}
      {...rest}
    >
      {icon && (
        <Box mr="4" fontSize="16">
          {React.createElement(icon)}
        </Box>
      )}
      {children}
    </Flex>
  </Link>
);

function HealthModeToggle() {
  const [isHealthMode, setIsHealthMode] = useState(false);

  useEffect(() => {
    const savedMode = localStorage.getItem('healthMode');
    setIsHealthMode(savedMode === 'true');
  }, []);

  const toggleHealthMode = () => {
    const newMode = !isHealthMode;
    setIsHealthMode(newMode);
    localStorage.setItem('healthMode', newMode.toString());
  };

  return (
    <Box position="absolute" bottom="4" left="4" right="4">
      <Button
        width="full"
        onClick={toggleHealthMode}
        leftIcon={<Icon as={FiHeart} fill={isHealthMode ? "currentColor" : "none"} />}
        colorScheme={isHealthMode ? "green" : "gray"}
        variant="solid"
        size="md"
        boxShadow="sm"
      >
        Health Mode {isHealthMode ? 'On' : 'Off'}
      </Button>
    </Box>
  );
}

export default Layout;