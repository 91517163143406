import React from 'react';
import { Link } from 'react-router-dom';

const buttonStyle = {
  padding: '10px 20px',
  borderRadius: '25px',
  fontWeight: 'bold',
  textDecoration: 'none',
  display: 'inline-block',
  transition: 'background-color 0.3s',
};

export default function LandingPage() {
  return (
    <div style={{ 
      minHeight: '100vh', 
      fontFamily: 'Arial, sans-serif',
      background: 'linear-gradient(to bottom, #f7fafc, #fff)',
      color: '#333',
    }}>
      <header style={{ 
        maxWidth: '1200px', 
        margin: '0 auto', 
        padding: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <Link to="/">
          <img src="/logo.png" alt="Logo" style={{ height: '240px', width: 'auto' }} />
        </Link>
        <nav>
          <Link to="/login" style={{ marginRight: '20px', color: '#4a5568', textDecoration: 'none' }}>Login</Link>
          <Link to="/register" style={{
            ...buttonStyle,
            backgroundColor: '#38b2ac',
            color: 'white',
          }}>Sign Up</Link>
        </nav>
      </header>

      <main style={{ maxWidth: '1200px', margin: '0 auto', padding: '40px 20px' }}>
        <section style={{ textAlign: 'center', marginBottom: '60px' }}>
          <h1 style={{ fontSize: '3rem', marginBottom: '20px', color: '#2d3748' }}>Simplify Your Meal Planning</h1>
          <p style={{ fontSize: '1.25rem', marginBottom: '30px', color: '#4a5568' }}>
            Manage ingredients, plan meals, and get personalized recipe suggestions.
          </p>
          <div>
            <Link to="/register" style={{
              ...buttonStyle,
              backgroundColor: '#38b2ac',
              color: 'white',
              marginRight: '20px',
            }}>Get Started</Link>
            <Link to="/learn-more" style={{
              ...buttonStyle,
              border: '2px solid #cbd5e0',
              color: '#4a5568',
            }}>Learn More</Link>
          </div>
        </section>

        <section style={{ 
          display: 'grid', 
          gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', 
          gap: '30px',
          marginBottom: '60px',
        }}>
          {[
            {
              title: 'Ingredient Management',
              description: 'Keep track of your pantry inventory in real-time. Add, update, and monitor expiration dates of your ingredients to reduce food waste and stay organized.'
            },
            {
              title: 'Meal Planning',
              description: 'Create weekly meal plans with ease. Browse recipes that match your available ingredients and dietary preferences, making cooking more efficient and enjoyable.'
            },
            {
              title: 'Recipe Suggestions',
              description: 'Get personalized recipe recommendations based on what\'s in your pantry. Discover new dishes and make the most of your available ingredients.'
            }
          ].map(({ title, description }) => (
            <div key={title} style={{ 
              backgroundColor: 'white', 
              borderRadius: '8px', 
              padding: '20px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}>
              <h3 style={{ fontSize: '1.25rem', marginBottom: '10px', color: '#2d3748' }}>{title}</h3>
              <p style={{ color: '#4a5568' }}>{description}</p>
            </div>
          ))}
        </section>

        <section style={{ textAlign: 'center' }}>
          <h2 style={{ fontSize: '2rem', marginBottom: '20px', color: '#2d3748' }}>Ready to simplify your cooking experience?</h2>
          <Link to="/register" style={{
            ...buttonStyle,
            backgroundColor: '#38b2ac',
            color: 'white',
          }}>Sign Up Now</Link>
        </section>
      </main>

      <footer style={{ 
        backgroundColor: '#f7fafc', 
        padding: '20px',
        textAlign: 'center',
        color: '#4a5568',
      }}>
        <p>&copy; 2024 Pantry. All rights reserved.</p>
      </footer>
    </div>
  );
}